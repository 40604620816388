import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function Covid19Page() {
  return (
    <Layout>
      <SEO
        title="COVID-19"
        keywords={[`A note on covid-19`]}
      />
      <div class="max-w-full">
        <div class="relative">
          <div class="relative lg:flex overflow-hidden">
            <div id="accomodations" class="relative lg:w-6/12 bg-white h-full">
              <div class="font-sans-serif relative py-12 lg:py-24 px-8 lg:px-16 text-black leading-relaxed">
                <p class="mb-6 text-2xl">
                  COVID-19
                </p>
                <p class="text-gray-900">
                  Dear Friends and Family,
                  <br></br>
                </p>
                <br></br>
                <p>
                  When we started planning our wedding, we were pretty sure the chances of a viral pandemic happening were slim to none. Well, we were wrong. We feel the need to address this as many guests will be traveling to join us for our special day. Our priority is the health and safety of all of our family and friends.
                </p>
                <br></br>
                <p>
                  Please continue to check our website for the most up to date information regarding how COVID-19 may affect travel and wedding plans. At this point in time, our wedding plans have not changed. We will continue to monitor the spread of the virus and CDC guidelines. We ask that you bear with us as we watchfully wait. 
                </p>
                <br></br>
                <p>
                  Due to the fluidity of the situation, we are extending the RSVP deadline on the invitations from April 30, 2020 to May 7, 2020.
                </p>
                <br></br>
                <p>
                  If you have any underlying health conditions or feel sick in any way as we get closer to the wedding, we ask that you celebrate with us from afar. Most of all, we hope that you all stay safe in the coming weeks. We're still getting married, and we can't wait to celebrate with you all, whenever it may be!
                </p>
                <br></br>
                <p>
                  If you have any questions, please contact our wedding planner <a href="mailto: sarah@socialblissevents.com" class="text-underline text-blue-600">Sarah Patton</a>.
                </p>
                <br></br>
                <p>
                  Love, 
                </p>
                <br></br>
                <p>
                  Justin and Betsy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Covid19Page;
